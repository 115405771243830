import { hasLocales, getLocales, setLocales } from '@/services/locales'
import i18n from '@/i18n'

const initLocale = async ({ state, commit }) => {
  if (hasLocales()) {
    const currentLocales = getLocales()

    commit('SET_SLUG', currentLocales.slug)
    commit('SET_LOCALE', currentLocales)
    i18n.locale = currentLocales.code
  } else {
    commit('SET_SLUG', state.langs[0].slug)
    commit('SET_LOCALE', state.langs[0])
    i18n.locale = state.langs[0].code
    setLocales(state.langs[0])
  }
}

const setLocale = ({ commit }, locale) => {
  commit('SET_SLUG', locale.slug)
  commit('SET_LOCALE', locale)
  i18n.locale = locale.code
  setLocales(locale)
}

const setUserRole = ({ commit }, role) => {
  commit('SET_USER_ROLE', role)
}

const setNoAccess = ({ commit }, access) => {
  commit('SET_NO_ACCESS', access)
}

export default {
  initLocale,
  setUserRole,
  setLocale,
  setNoAccess
}
