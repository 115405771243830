const SET_SLUG = (state, slug) => {
  state.slug = slug
  localStorage.setItem('slug', slug)
}

const SET_USER_ROLE = (state, role) => {
  state.userRole = role
  localStorage.setItem('role', role)
}

const SET_LOCALE = (state, locale) => {
  state.currentLocale = locale
}

const SET_NO_ACCESS = (state, access) => {
  state.noAccess = access
}

export default {
  SET_SLUG,
  SET_USER_ROLE,
  SET_LOCALE,
  SET_NO_ACCESS
}
