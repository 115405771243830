const isTeacher = state => state.userRole === 'teacher'

const getAppLangs = state => state.langs

const getSlug = state => state.slug

const getLocale = state => state.currentLocale

const getNoAccess = state => state.noAccess

export default {
  isTeacher,
  getAppLangs,
  getSlug,
  getLocale,
  getNoAccess
}
