<template>
  <div class="c-modal-sw">
    <div class="c-modal-sw__overlay">
      <div class="c-modal-sw__content">
        <div
          class="c-modal-sw__content-description"
          v-md-html="$t('MODAL_SW_DESC')"
        />
        <div class="c-modal-sw__footer">
          <FHButton
            class="c-modal-sw__button"
            @click.native="$emit('update-sw')"
            >{{ $t("MODAL_SW_BUTTON") }}</FHButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FHButton from "@forlagshuset/v-button";

export default {
  name: "ModalSWUpdate",

  components: {
    FHButton,
  },
};
</script>

<style lang="scss">
.c-modal-sw {
  font-family: CircularPro, sans-serif;
}

.c-modal-sw__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000cc;
  z-index: 99999;
}
.c-modal-sw__content {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;
  max-width: $medium;
  width: 90%;
  background: $color-white;
  z-index: 999999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: auto;
}

.c-modal-sw__footer,
.c-modal-sw__content-description {
  font-size: 1.2rem;
  margin: 3rem;
  text-align: center;
}

.c-modal-sw__button {
  background: $color-brand;
  color: $color-white;
  padding: 0.7rem 4rem;
  font-size: 1.3rem;
  border-radius: 2rem;
  border: 3px $color-brand solid;
  line-height: normal;
}
</style>
