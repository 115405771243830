<template>
  <div class="c-overview o-wrapper">
    <ContentLoader v-if="loading" height="500">
      <rect x="0" y="1rem" width="60%" height="1rem"></rect>
      <rect x="0" y="2.5rem" width="30%" height="100%"></rect>
      <rect x="34%" y="2.5rem" width="30%" height="100%"></rect>
      <rect x="68%" y="2.5rem" width="30%" height="100%"></rect>
    </ContentLoader>
    <div v-else>
      <Logotype class="c-overview__bg u-py u-px" />
      <div v-if="intro"
        class="c-overview__intro u-px u-px--3 u-py"
        v-md-html="intro" />
      <div v-for="parent in overviewList"
        :key="parent.id">
        <div v-if="parent.units.length"
          :class="`c-overview__parent-unit c-overview__parent--${parent.slug} u-mb`">
          <VHeading header="h1"
            class="c-overview__parent-unit-title o-heading u-mb">
            {{ parent.name }}
          </VHeading>
          <div class="c-overview__parent-units">
            <UnitTile v-for="unit in parent.units"
              class="c-overview__parent-units-item"
              :class="[ 
                `c-overview_unit--${unit.slug}`,
                { 'c-overview_unit--disabled': unit.orderedLessons.length === 0 } 
              ]"
              :key="unit.id"
              :title="unit.name"
              :slug="unit.slug"
              :text="unit.description"
              :cover="unit.cover" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'
import { ContentLoader } from 'vue-content-loader'
import Logotype from '@/assets/svg/Logotype'
import VHeading from '@forlagshuset/v-heading'
import UnitTile from '@/components/UnitTile'

export default {
  name: 'Home',
  components: {
    ContentLoader,
    Logotype,
    VHeading,
    UnitTile
  },

  setup(props, ctx) {
    const loading = ref(true)
    const overviewList = ref([])
    const appSlug = ctx.root.$store.getters['appConfig/getLocale'].slug
    const intro = ref('')

    onMounted (async () => {
      await ctx.root.$store.dispatch('content/setCourse', appSlug)
      const course = await ctx.root.$store.getters['content/getCourse']
      await ctx.root.$store.dispatch('content/setUnits', appSlug)
      const units = await ctx.root.$store.getters['content/getUnits']
      
      intro.value = course.description

      //gather parents
      units.forEach((unit, j) => {
        if(!unit.parent){
          unit.units = []
          unit.index = j
          overviewList.value.push(unit)
        }
        if(j === units.length - 1) {
          //insert children
          overviewList.value.map(parent => {
            parent.units = units.filter(unit => {
              if (unit.cover && unit.cover.image_type) {
                const coverURL = unit.cover.image_type.file.url
                const param = 'h_330,c_scale'
                unit.cover.credit = unit.cover.image_type.license ? unit.cover.image_type.license.credit : ''
                unit.cover.url = addCloudinaryUrlParams(coverURL, param)
              }

              if(unit.parent && unit.parent.id === parent.id) {
                return unit
              }
            })

            return parent
          })
        }
      })
      loading.value = false;
    })

    return {
      loading,
      appSlug,
      overviewList,
      intro
    }
  },
}
</script>

<style lang="scss">
.c-overview {
  padding: 2rem;
  position: relative;
  overflow: hidden;
}
.c-overview__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right:0;
}
.c-overview__intro {
  position: relative;
  z-index: 1;
  font-size: 1.2rem;
  background-color: $overview-intro-bg;
  box-shadow: 0.1rem 0px 0.75rem $color-light-mid-grey;

  @include bp-down(medium) {
    text-align: center;
  }

  h2 {
    margin-top: 1rem;
  }

  a {
    color: $color-black;
    font-weight: 700;
  }
}
.c-overview__parent-unit {
  display: block;
  position: relative;
  z-index: 1;
}
.c-overview__parent-units {
  display: flex;
  flex-wrap: wrap;
}
.c-overview__parent-units-item {
  background: $activity-card-bg;
  box-shadow: 0px 1px 10px $activity-card-shadow;
}

.c-overview__parent-unit-title {
  h1 {
    font-size: 2.4rem;
    text-align: center;

    @include bp(500px) {
      text-align: left;
    }

    @include bp(medium) {
      font-size: 3.05rem;
    }
  }
}
</style>
