<template>
  <div class="c-login u-mya">
    <TheButton
      class="c-login__button"
      @click.native="logout"
    >
      {{ $t('TOPBAR_LOGOUT') }}
    </TheButton>
  </div>
</template>

<script>
import TheButton from '@forlagshuset/v-button'

export default {
  components: {
    TheButton
  },

  methods: {
    logout() {
      this.$root.$store.dispatch('user/logoutUser')
    }
  }
}
</script>

<style lang="scss">
.c-login {
  margin-left: auto;
}
.c-login__button {
  padding: 0;
  background: none;
  border: 0;
  font-family: CircularPro, sans-serif;
}
</style>
