const SET_MODAL = (state, content) => {
  state.content = content
  state.isOpen = true
}

const CLEAR_MODAL = state => {
  state.content = null
  state.isOpen = false
}

export default {
  SET_MODAL,
  CLEAR_MODAL
}
