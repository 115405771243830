const nb = {
  LANGUAGE_CODE: "nb_NO",
  LANGUAGE_NAME: "Bokmål",
  NAV_ACTIVITY_CARDS: "Aktivitetskort",
  NAV_FIND_CONCEPT: "Finn fagstoff",
  NAV_TOOLS: "Verktøy",
  TOPBAR_LANGUAGE: "Språk",
  TOPBAR_LOGOUT: "Logg ut",
  UNIT_TABS_LABEL: "Utforsk et tema",
  UNIT_TILE_BTN: "Les mer",
  UNIT_READ_MORE: "Les mer",
  LESSON_TABS_LABEL: "Velg artikkel",
  ACTIVITY_TABS_LABEL: "Artikler for å løse oppgaven",
  ACTIVITY_BUTTON: "Velg",
  CONCEPTS_HEADER: "Ord du bør kunne:",
  SEARCH_CONCEPTS: "Begreper",
  SEARCH_ARTICLES: "Artikler",
  SEARCH_THEMES: "Temaer",
  SEARCH_MULTIMEDIA: "Multimedia",
  SEARCH_COUNT_INFO: "{count} treff på",
  SEARCH_PLACEHOLDER: "Søk etter begreper",
  LOGIN_HEADING: "Velkommen til Aktør!",
  LOGIN_NO_ACCESS:
    'Obs! Du har ingen tilgang til dette produktet.<br />Er du elev og mangler tilgang, må du kontakte læreren din eller administrator på skolen.<br />For å få tilgang til Aktør, kontakt kundeservice på <a href="mailto:digitalt@fagbokforlaget.no">digitalt@fagbokforlaget.no</a>. Du kan også gå til <a href="https://fagbokforlaget.no/Akt%C3%B8r-digitalt-l%C3%A6reverk/I9788211031938" target="_blank">Fagbokforlaget</a> for mer informasjon om verket og avtaler.',
  LOGIN_TEXT:
    "Vårt nye, heldigitale verk i samfunnsfag for ungdomstrinnet. Verket legger til rette for aktivitet, utforskning og engasjement med eleven som Aktør.",
  LOGIN_BUTTON: "Logg inn",
  LOGIN_BUTTON_SECOND: "Les mer og få tilgang",
  LOGIN_STUDENT: "Elever",
  LOGIN_TEACHER: "Lærere",
  ARTICLE_SUMMARY: "Sammendrag",
  SEARCH_FILTERS: "Filtrer",
  FOOTER_ABOUT_AUTHORS: "Om forfatterne",
  CONCEPT_SEE_FULL_DESC: "Se full beskrivelse",
  DRAWER_MENU_HOMEPAGE: "Hjem",
  PLAY_AUDIO: "Spill av lyd",
  DOWNLOAD_BUTTON: "Last ned",
  DOWNLOAD_TEXT: "Last ned rollekortene her:",
  LESSON_HEADER: "Aktiver",
  LESSON_FOCUS: "Fokus {count}:",
  AUDIOTYPE_NAME: "Hørespill",
  MODAL_SW_DESC: "Oppdater siden for å vise nyeste versjon av Aktør",
  MODAL_SW_BUTTON: "OK",
};

export default nb;
