import { fetchSH } from '@/api'
import { fetchCourseBySlug, fetchCourseUnits, fetchUnitLessons, fetchLessonsField } from '@/services/strapi'
import { parseLearningElements } from '@/utils'

const setCourse = async ({ state, commit }, appSlug) => {
  if (state.course !== null) {
    return
  }

  const course = await fetchCourseBySlug(appSlug)

  commit('SET_COURSE', course)

  return course
}

const setUnits = async ({ state, commit }, appSlug) => {
  if (state.units.length > 0) {
    return
  }

  const units = (await fetchCourseUnits(appSlug))
  commit('SET_UNITS', units)

  return units
}

const setUnitParsedContent = async ({ state, commit }, { slug, content }) => {
  let unitHasParsedContent = false

  state.units.filter(u => {
    if (u.slug === slug) {
      if ('parsedContent' in u) {
        unitHasParsedContent = true
      }
    }
  })
  if (unitHasParsedContent === true) {
    return
  }

  const parsedContent = parseLearningElements(content)

  commit('SET_UNIT_PARSED_CONTENT', { slug, parsedContent })

  return parsedContent
}

const setLessons = async ({ state, commit }, slug) => {
  if (
    (state.units.filter(u => u.slug === slug)[0]).lessons &&
    typeof (state.units.filter(u => u.slug === slug)[0]).lessons[0] === 'object'
  ) return

  const lessons = await fetchUnitLessons(slug)
  commit('SET_LESSONS', { slug, lessons })

  return lessons
}

const setLessonsField = async ({ state, commit }, { slug, field }) => {
  if ((state.lessons[slug] !== undefined) && (field in state.lessons[slug])) {
    return
  }

  const fieldValue = await fetchLessonsField(slug, field)

  commit('SET_LESSONS_FIELD', { slug, field, fieldValue: fieldValue[0][field] })

  return fieldValue[0][field]
}

const setLessonActivities = async ({ state, commit }, { unitSlug, lessonSlug }) => {
  const unit = state.units.filter(u => u.slug === unitSlug)[0]
  const lesson = unit.lessons.filter(l => l.slug === lessonSlug)[0]
  if (lesson !== undefined && lesson.activities) {
    return
  }
  const fieldValue = await (await fetchSH('tag-types/' + lesson.keywords[0].id))

  commit('SET_LESSON_ACTIVITIES', { unitSlug: unitSlug, lessonSlug: lessonSlug, fieldValue: fieldValue.activities })

  return fieldValue.activities
}

const setTags = ({ commit }, tags) => {
  commit('SET_TAGS', tags)
}

export default {
  setCourse,
  setUnits,
  setUnitParsedContent,
  setLessons,
  setLessonsField,
  setLessonActivities,
  setTags
}