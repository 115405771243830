<template>
  <FHButton class="c-hamburger">
    <span class="c-hamburger__line" v-for="i in Array(3) " :key="i"></span>
  </FHButton>
</template>

<script>
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    FHButton
  }  
}
</script>

<style lang="scss">
.c-hamburger {
  display: flex;
  flex-direction: column;
  background: none;
  cursor: pointer;

  @include bp (1150px) {
    display: none;
  }
}

.c-hamburger__line {
  width: 1.5rem;
  height: 0.125rem;
  border-radius: 0.6875rem;
  margin: 0.1875rem 0;
  background: $color-black;
}
</style>
