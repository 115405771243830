<template>
  <div id="app">
    <ModalSWUpdate v-if="updateExists" @update-sw="refreshApp" />
    <Topbar @open-menu="openMenu" />
    <DrawerMenu :has-open-menu="hasOpenMenu" @close-menu="closeMenu" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Footer from "@/components/Footer";
import DrawerMenu from "@/components/DrawerMenu";
import ModalSWUpdate from "@/components/ModalSWUpdate";
import update from "@/mixins/update";

export default {
  components: {
    Topbar,
    Footer,
    DrawerMenu,
    ModalSWUpdate,
  },

  data() {
    return {
      hasOpenMenu: false,
    };
  },
  mixins: [update],
  methods: {
    openMenu() {
      this.hasOpenMenu = true;
    },

    closeMenu() {
      this.hasOpenMenu = false;
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: 16px;

  @include bp(medium) {
    font-size: 18px;
  }
}
#app {
  font-family: "Lyon Display Web", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $body-bg;
  min-height: 100vh;
  overflow-y: hidden;
  line-height: 1.5;
}
*:focus {
  outline: 2px dashed $color-black !important;
}
</style>
