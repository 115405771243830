import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import auth from '@/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/unit/:unitSlug/:lessonSlug?',
    name: 'Unit',
    component: () => import('@/views/Unit'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/lesson/:unitSlug/:lessonSlug?',
    name: 'Lesson',
    component: () => import('@/views/Lesson'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/activity/:unitSlug/:lessonSlug/:activityTypeSlug',
    name: 'Activity',
    component: () => import('@/views/Activity'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/article/:unitSlug/:articleSlug',
    name: 'Article',
    component: () => import('@/views/Article'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tools/:slug',
    name: 'Tools',
    component: () => import('@/views/Tools'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/authors',
    name: 'Authors',
    component: () => import('@/views/Authors.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      hideHeader: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user.user) {
      //if user is set, proceed to next page
      next()
    } else {
      //if not, authorize
      auth
        .checkToken()
        .then(() => {
          if (to.query.token) {
            delete to.query.token
          }
          return auth.checkAccess([process.env.VUE_APP_ACCESS_ID])
        })
        .then(res => {
          if (
            res.products.some(
              product => product.id == process.env.VUE_APP_ACCESS_ID,
            )
          ) {
            store.dispatch('appConfig/setNoAccess', false)
            store.dispatch('user/setToken')
            store.dispatch('user/setUser', res.user)

            next()
          } else {
            //here should be info about no access
            store.dispatch('appConfig/setNoAccess', true)
            next({ path: '/login' })
          }
        })
        .catch(() => {
          next({ path: '/login' })
        })
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.name !== 'Lesson') {
    window.scrollTo(0, 0)
  }
  const activityLink = document.querySelector('.c-nav__item--activity')
  const searchLinkEl = document.querySelector(
    '.c-nav__item--search .c-nav__link',
  )
  const toolsLinkEl = document.querySelector('.c-nav__item--tools .c-nav__link')

  if (to.name === 'Search' || to.name === 'Tools') {
    activityLink ? activityLink.classList.add('hide-active') : ''
  } else {
    activityLink ? activityLink.classList.remove('hide-active') : ''
  }

  if (activityLink && searchLinkEl && to.name === 'Lesson') {
    activityLink.classList.add('hide-active')
    searchLinkEl.classList.add('router-link-active')
  }

  if (searchLinkEl && from.name === 'Lesson' && to.name !== 'Lesson') {
    searchLinkEl.classList.remove('router-link-active')
  }

  if (activityLink && toolsLinkEl && to.params.unitSlug === 'aktor-verktoy') {
    activityLink.classList.add('hide-active')
    toolsLinkEl.classList.add('router-link-active')
  }

  if (
    toolsLinkEl &&
    from.params.unitSlug === 'aktor-verktoy' &&
    to.name !== 'Tools'
  ) {
    toolsLinkEl.classList.remove('router-link-active')
  }
})

export default router
