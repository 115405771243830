<template>
  <div class="c-footer">
    <FHButton href="/authors"
      class="c-footer__link o-wrapper">{{ $t('FOOTER_ABOUT_AUTHORS') }}</FHButton>
    <FHFooter hide-social />
  </div>
</template>
<script>
import FHFooter from '@forlagshuset/v-small-footer'
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    FHFooter,
    FHButton
  }
}
</script>

<style lang="scss">
.c-footer {
  position: relative;
  z-index: 1;
  font-family: CircularPro, sans-serif;
  .c-small-footer {
    font-family: CircularPro, sans-serif;
    background-color: $color-white;
    .c-contact-link__text {
      color: $color-red;
    }
    .c-logo {
      path {
        fill: $color-black;
      }
    }
    path {
      fill: $color-red;
    }
    *:focus {
      outline: 2px dashed $color-black;
      &:active {
        outline: 0px !important;
      }
    }
  }
}
.c-footer__link {
  display: none;

  @include bp(1150px) {
    display: block;
    color: $color-red;
    border: none;
    background: none;
  }
}
</style>