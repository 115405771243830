const nn = {
  LANGUAGE_CODE: "nn_NO",
  LANGUAGE_NAME: "Nynorsk",
  NAV_ACTIVITY_CARDS: "Aktivitetskort",
  NAV_FIND_CONCEPT: "Finn fagstoff",
  NAV_TOOLS: "Verktøy",
  TOPBAR_LANGUAGE: "Språk",
  TOPBAR_LOGOUT: "Logg ut",
  UNIT_TABS_LABEL: "Utforsk eit tema",
  UNIT_TILE_BTN: "Les meir",
  UNIT_READ_MORE: "Les meir",
  LESSON_TABS_LABEL: "Vel artikkel",
  ACTIVITY_TABS_LABEL: "Artiklar for å løyse oppgåva",
  ACTIVITY_BUTTON: "Vel",
  CONCEPTS_HEADER: "Omgrep du bør kunne:",
  SEARCH_CONCEPTS: "Omgrep",
  SEARCH_ARTICLES: "Artiklar",
  SEARCH_THEMES: "Tema",
  SEARCH_MULTIMEDIA: "Multimedia",
  SEARCH_COUNT_INFO: "{count} treff på",
  SEARCH_PLACEHOLDER: "Søk etter omgrep",
  LOGIN_HEADING: "Velkomen til Aktør!",
  LOGIN_NO_ACCESS:
    'Obs! Du har ingen tilgang til dette produktet.<br />Er du elev og mangler tilgang, må du kontakte læreren din eller administrator på skolen.<br />For å få tilgang til Aktør, kontakt kundeservice på <a href="mailto:digitalt@fagbokforlaget.no">digitalt@fagbokforlaget.no</a>. Du kan også gå til <a href="https://fagbokforlaget.no/Akt%C3%B8r-digitalt-l%C3%A6reverk/I9788211031938" target="_blank">Fagbokforlaget</a> for mer informasjon om verket og avtaler.',
  LOGIN_TEXT:
    "Vårt nye, heildigitale verk i samfunnsfag for ungdomstrinnet. Verket legg til rette for aktivitet, utforsking og engasjement med eleven som Aktør.",
  LOGIN_BUTTON: "Logg inn",
  LOGIN_BUTTON_SECOND: "Les meir og få tilgang",
  LOGIN_STUDENT: "Elevar",
  LOGIN_TEACHER: "Lærarar",
  ARTICLE_SUMMARY: "Samandrag",
  SEARCH_FILTERS: "Filtrer",
  FOOTER_ABOUT_AUTHORS: "Om forfattarane",
  CONCEPT_SEE_FULL_DESC: "Sjå heile teksten",
  DRAWER_MENU_HOMEPAGE: "Heim",
  PLAY_AUDIO: "Spel av lyd",
  DOWNLOAD_BUTTON: "Last ned",
  DOWNLOAD_TEXT: "Last ned rollekortene her:",
  LESSON_HEADER: "Aktiver",
  LESSON_FOCUS: "Fokus {count}:",
  AUDIOTYPE_NAME: "Høyrespel",
  MODAL_SW_DESC: "Oppdater sida for å vise nyaste versjon av Aktør",
  MODAL_SW_BUTTON: "OK",
};

export default nn;
