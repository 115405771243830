const SET_FILTERS = (state, filters) => {
  state.selected = filters
}

const SET_SELECTED = (state, selected) => {
  state.selected = selected
}

export default {
  SET_FILTERS,
  SET_SELECTED, 
}
