import { markdownToHtml } from "../utils"

const mdHtml = {
  bind: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
  },

  update: function (el, binding) {
    el.innerHTML = markdownToHtml(binding.value)
  }
}

export {
  mdHtml
}