const getFilters = state => {
  const content = state.filters

  return content
}

const getSelected = state => {
  const content = state.selected

  return content
}

export default {
  getFilters,
  getSelected,
}
  