<template>
  <div class="c-topbar" :class="{'c-topbar--minimal': $route.meta.hideHeader}">
    <Hamburger @click.native="openMenu" v-if="!$route.meta.hideHeader" class="c-topbar__hamburger" />
    <router-link :to="{ name: 'Home' }" class="c-topbar__logo-wrapper">
      <img class="c-topbar__logo"
        alt="Aktor logo"
        src="../assets/logo.png">
    </router-link>
    <Nav v-if="!$route.meta.hideHeader" class="c-topbar__nav" />
    <LanguageSelect />
    <Login v-if="!$route.meta.hideHeader" class="c-topbar__login" />
  </div>
</template>

<script>
import Hamburger from '@/components/Hamburger'
import Nav from '@/components/Nav'
import LanguageSelect from '@/components/LanguageSelect'
import Login from '@/components/Login'

export default {
  components: {
    Hamburger,
    Nav,
    LanguageSelect,
    Login
  },

  methods: {
    openMenu() {
      this.$emit('open-menu')
    }
  }
}
</script>

<style lang="scss">
.c-topbar {
  position: relative;
  display: flex;
  padding: 2rem;
  padding-bottom: 6rem;
  font-family: CircularPro, sans-serif;
  align-items: center;

  @include bp(1150px) {
    padding-bottom: 2rem;
    border-bottom: 1px solid $topbar-border;
  }

  &--minimal {
    padding-bottom: 0;
    border: 0;
    background: $color-medium-grey;
  }
}

.c-topbar__logo-wrapper {
  position: relative;
  z-index: 9;

  @include bp-down(1150px) {
    margin: 0 auto;
  }
}

.c-topbar__hamburger {
  margin-top: 0.3rem;
}

.c-topbar__logo {
  width: 6.625rem;
  height: auto;

  @include bp(1150px) {
    width: auto;
    height: 2rem;
  }
}

.c-topbar__login {
  position: relative;
  z-index: 9;
  margin-left: 1rem;

  @include bp-down(1150px) {
    display: none;
  }
}
</style>