import Vue from 'vue'
import Vuex from 'vuex'

import appConfig from './modules/appconfig'
import banner from './modules/banner'
import user from './modules/user'
import modal from './modules/modal'
import content from './modules/content'
import filters from './modules/filters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    appConfig,
    banner,
    user,
    modal,
    content,
    filters,
  }
})
