<template>
  <div class="c-drawer-menu" v-if="hasOpenMenu">
    <div class="c-drawer-menu__overlay" @click="closeDrawer" />
    <div class="c-drawer-menu__inner">
      <FocusLock :return-focus="true">
        <FHButton class="c-drawer-menu__close-btn" @click.native="closeDrawer">
          <Close />
        </FHButton>
        <div class="c-drawer-menu__logo-wrapper">
          <img class="c-drawer-menu__logo"
            alt="Aktor logo"
            src="../assets/logo.png"
          />
        </div>
        <nav class="c-drawer-menu__nav">
          <ul class="c-drawer-menu__nav-list">
            <li class="c-drawer-menu__nav-list-item">
              <router-link class="c-drawer-menu__nav-list-link" to="/" @click.native="closeDrawer">
                {{ $t('DRAWER_MENU_HOMEPAGE') }}
              </router-link>
            </li>
            <li class="c-drawer-menu__nav-list-item">
              <router-link class="c-drawer-menu__nav-list-link" to="/authors" @click.native="closeDrawer">
                {{ $t('FOOTER_ABOUT_AUTHORS') }}
              </router-link>
            </li>
          </ul>
        </nav>
        <Login @click.native="closeDrawer" class="c-drawer-menu__logout" />
      </FocusLock>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Login'
import Close from '@/assets/svg/Close'
import FHButton from '@forlagshuset/v-button'
import FocusLock from 'vue-focus-lock'

export default {
  name: 'DrawerMenu',

  components: {
    Login,
    Close,
    FHButton,
    FocusLock
  },

  methods: {
    closeDrawer() {
      this.$emit('close-menu')
    }
  },

  props: {
    hasOpenMenu: Boolean
  }
}
</script>

<style lang="scss">
.c-drawer-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;

  @include bp (1150px) {
    display: none;
  }
}

.c-drawer-menu__overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba($color-black, 0.6);
}

.c-drawer-menu__logo {
  margin-top: 1rem;
  width: 6.625rem;
}

.c-drawer-menu__logout .c-login__button {
  margin-top: 1rem;
}

.c-drawer-menu__nav-list {
  margin: 0;
  padding: 0;
}

.c-drawer-menu__nav-list-item {
  list-style-type: none;
  margin-top: 1rem;
}

.c-drawer-menu__nav-list-link {
  text-decoration: none;
  color: $color-black;
  font-family: 'CircularPro', sans-serif;
}

.c-drawer-menu__inner {
  position: relative;
  background: $color-white;
  width: 17rem;
  height: 100%;
  padding: 1.6875rem;
  z-index: 1;
}

.c-drawer-menu__close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background: none;
}
</style>