const ADD_BANNER_HEIGHT = (state, height) => {
  state.heights.push(height)
}

const CLEAR_BANNER = state => {
  state.heights = []
}

export default {
  ADD_BANNER_HEIGHT,
  CLEAR_BANNER
}
