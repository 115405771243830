<template>
  <div class="c-language-select u-px">
    <div class="c-language-select__label">
      {{ $t('TOPBAR_LANGUAGE') }}:
    </div>

    <VSelect v-if="currentLocale"
      :selectedIndex="selectedLanguage"
      :options="languages"
      :size='4'
      @item-selected="onLanguageChange"
    />
  </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import VSelect from '@forlagshuset/v-dropdown'

export default {
  name: 'LanguageSelect',

  components: {
    VSelect,
  },

  setup(props, ctx) {
    const languages = computed(() => ctx.root.$store.getters['appConfig/getAppLangs'])
    const currentLocale = ref(null)
    const selectedLanguage = ref(0)

    onMounted(() => {
      ctx.root.$store.dispatch('appConfig/initLocale')
      currentLocale.value = ctx.root.$store.getters['appConfig/getLocale']
      selectedLanguage.value = languages.value.findIndex(l => l.code == currentLocale.value.code)
    })

    const onLanguageChange = (e) => {
      ctx.root.$store.dispatch('appConfig/setLocale', languages.value[e])
      window.location = window.location.origin
    }

    return {
      languages,
      currentLocale,
      selectedLanguage,
      onLanguageChange
    }
  }
}
</script>

<style lang="scss">
.c-language-select {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;

  .c-topbar--minimal & {
    display: none;
  }
}
.c-language-select__label {
  margin-right: 0.5rem;
}
.c-dropdown__dropdown {
  background-color: $color-very-light-grey;
  padding: 0;
  margin: 2px;
}
.c-dropdown__item {
  width: 100%;

  &--focused {
    outline: none;
  }
}
.c-dropdown__icon {
  top: 0;
}
.c-dropdown__item-icon-arrow {
  display: none;
}
</style>
