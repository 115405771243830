const SET_TAGS = (state, tags) => {
  state.tags = tags
}

const SET_COURSE = (state, course) => {
  state.course = course
}

const SET_UNITS = (state, units) => {
  state.units = units
}

const SET_UNIT_PARSED_CONTENT = (state, { slug, parsedContent }) => {
  state.units.filter(u => {
    if (u.slug === slug) {
      u.parsedContent = parsedContent
    }
  })
}

const SET_LESSONS = (state, { slug, lessons }) => {
  state.units.filter(u => {
    if (u.slug === slug) {
      u.lessons = lessons
    }
  })
}

const SET_LESSONS_FIELD = (state, { slug, field, fieldValue }) => {
  if (state.lessons[slug] === undefined) {
    state.lessons[slug] = {}
  }

  Object.defineProperty(state.lessons[slug], field, {
    value: fieldValue,
    writable: true,
  })
}

const SET_LESSON_ACTIVITIES = (state, { unitSlug, lessonSlug, fieldValue }) => {
  state.units.filter(u => {
    if (u.slug === unitSlug) {
      u.lessons.filter(l => {
        if (l.slug === lessonSlug) {
          l.activities = fieldValue
        }
      })
    }
  })
}

export default {
  SET_TAGS,
  SET_COURSE,
  SET_UNITS,
  SET_UNIT_PARSED_CONTENT,
  SET_LESSONS,
  SET_LESSONS_FIELD,
  SET_LESSON_ACTIVITIES,
}
