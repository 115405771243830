import showdown from 'showdown'

const markdownToHtml = md => {
  const converter = new showdown.Converter()
  converter.setOption('tables', true)

  return converter.makeHtml(md)
}

const addCloudinaryUrlParams = (url, param) => {
  let newURL
  const splitURL = url.split('upload/')

  newURL = `${splitURL[0]}upload/${param}/${splitURL[1]}`

  return newURL
}

const parseLearningElements = (resources) => {
  let inSection = false
  let sectionItem = null
  const parsedLearningElements = []

  resources.forEach(async element => {
    if(componentNameNormalize(element.__component) === 'ComponentLearningElementSectionTagElement') {
      inSection = true
      sectionItem = element
      sectionItem.items = []
      sectionItem.properties.forEach(prop => {
        const { key, value } = prop

        if (key === 'type') {
          sectionItem.__component = value.replace(/\s/g,'')
        } else {
          sectionItem[key] = value.replace(/\s/g,'')
        }
      })
    } else if (componentNameNormalize(element.__component) === 'ComponentLearningElementSectionEndTagElement') {
      inSection = false
      parsedLearningElements.push(sectionItem)
      sectionItem = null
    } else {
      if (inSection) {
        sectionItem.items.push(element)

        return
      }

      if (componentNameNormalize(element.__component) === 'ComponentLearningElementDbokLinkElement') {
        //pull title
        const book = await (await fetch(`https://booksync.fagbokforlaget.no/api/pub/${element._id}`)).json()
        element.bookTitle = book.name
        //pull image
        element.image_type.url = book.cover
      }
      parsedLearningElements.push(element)
    }
  })

  return parsedLearningElements
}

const componentNameNormalize = name => {
  if (!name ) return
  if (name.indexOf('-') === -1 || name.indexOf('.') === -1) return name

  const splitDots = name.split('.')
  const removedDots = splitDots.map(string => {
    return string[0].toUpperCase() + string.slice(1)
  }).join('')
  const splitHyphens = removedDots.split('-')
  const removedHyphensAndDots = splitHyphens.map(string => {
    return string[0].toUpperCase() + string.slice(1)
  }).join('')
  const modify = `Component${removedHyphensAndDots}`

  return modify
}

const isFullWidth = (width, height, ratio = 0.85) => {
  if (width / height > ratio) {
    return true
  } else {
    return false
  }
}

export {
  componentNameNormalize,
  addCloudinaryUrlParams,
  markdownToHtml,
  parseLearningElements,
  isFullWidth,
}