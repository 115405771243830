const setFilters = async ({ state, commit }, filters) => {
  if (state.filters.length > 0) {
    return
  }

  commit('SET_FILTERS', filters)

  return filters
}

const setSelected = async ({ commit }, selected) => {
  commit('SET_SELECTED', selected)

  return selected
}

export default {
  setFilters,
  setSelected,
}