import auth from '@/auth'
import jwt_decode from 'jwt-decode'

const teacherRoles = ['teacher', 'institution', 'institution-owner', 'institution-manager', 'organization', 'admin', 'internal']

const setUser = ({commit}, user) => {
  commit('SET_USER', user)
}

const setToken = ({dispatch, commit}) => {
  const decodedToken = jwt_decode(auth.token)

  if (decodedToken.roles.some(role => teacherRoles.includes(role))) {
    dispatch('appConfig/setUserRole', 'teacher', { root: true })
  } else {
    dispatch('appConfig/setUserRole', 'student', { root: true })
  }

  commit('SET_ACCESS_TOKEN')
}

const logoutUser = ({commit}) => {
  const redirectUrl = `${auth.logoutUrl}&token=${encodeURIComponent(auth.token)}`

  commit('LOGOUT_USER')
  auth.logout(redirectUrl)
}

export default {
  setUser,
  setToken,
  logoutUser
}
