<template>
  <router-link
    :to="lessonUrl"
    :key="slug"
    class="c-unit-tile"
    :class="{ 'c-unit-tile--no-lessons' : !hasLessons }">
    <VHeading header="h2"
      class="c-unit-tile__title"
      :text="title" />
    <picture v-if="cover.url"
      class="c-unit-tile__pic u-mb">
      <img
        :src="`${cover.url}`"
        :title="cover.credit"
        class="c-unit-tile__img"
      />
    </picture>
    <div class="c-unit-tile__text">
      {{ text | truncate(150) }}
    </div>
    <FHButton class="c-unit-tile__btn" tabindex="-1">
      {{ $t('UNIT_TILE_BTN') }}
    </FHButton>
  </router-link>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    VHeading,
    FHButton
  },

  props: {
    title: String,
    slug: String,
    text: String,
    cover: Object,
    hasLessons: {
      type: Boolean,
      default: true,
    }  
  },

  computed: {
    lessonUrl(){
      return this.hasLessons ?  "/unit/"+this.slug :  '#'
    }
  }, 
}
</script>

<style scoped lang="scss">
.c-unit-tile {
  color: inherit;
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100px;
  text-decoration: none;
  overflow: hidden;
  height: auto;
  padding: 1rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  transition: 0.3s ease-in-out;
  transition-property: background, color;

  &--no-lessons {
    pointer-events: none;
    background: rgba($color-grey-lighter, 0.3);
  } 

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @include bp(500px) {
    flex: 0 0 330px;
  }

  @include bp(1024px){
    flex: 0 0 calc(33.333333% - 3.35rem)
  }

  @include bp-down(500px) {
    border-radius: 5px;
  }

  @include bp-down(947px) {
    margin-left: auto;
    margin-right: auto;
  }

  &[class*="c-overview_unit--aktor-parent-1-unit-1"] {
    .c-unit-tile__img {
      margin-left: -19.5rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-1-unit-2"] {
    .c-unit-tile__img {
      margin-left: -4rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-1-unit-3"] {
    .c-unit-tile__img {
      margin-left: -10rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-2-unit-1"] {
    .c-unit-tile__img {
      margin-left: -7.5rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-2-unit-2"] {
    .c-unit-tile__img {
      margin-left: -8rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-2-unit-3"] {
    .c-unit-tile__img {
      margin-left: -19rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-2-unit-4"] {
    .c-unit-tile__img {
      margin-left: -10rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-3-unit-1"] {
    .c-unit-tile__img {
      margin-left: -1.75rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-3-unit-2"] {
    .c-unit-tile__img {
      margin-left: -21rem;
    }
  }
  &[class*="c-overview_unit--aktor-parent-3-unit-3"] {
    .c-unit-tile__img {
      margin-left: -11rem;
    }
  }

  &.c-overview_unit--disabled {
    pointer-events: none;
    [class*="c-overview__parent--aktor-parent-1"] & {
      background: $unit-1-color-brand-light;
    }
    [class*="c-overview__parent--aktor-parent-2"] & {
      background: $unit-2-color-brand-light;
    }
    [class*="c-overview__parent--aktor-parent-3"] & {
      background: $unit-3-color-brand-light;
    }
  }

  &:hover {
    [class*="c-overview__parent--aktor-parent-1"] & {
      color: $color-white;
      background: $unit-1-color-brand;
    }
    [class*="c-overview__parent--aktor-parent-2"] & {
      color: $color-white;
      background: $unit-2-color-brand;
    }
    [class*="c-overview__parent--aktor-parent-3"] & {
      background: $unit-3-color-brand;
    }
  }
}
.c-unit-tile__title {
  text-align: center;
  font-size: 1.1rem;
  line-height: normal;
  margin: -1rem 0;

  @include bp(500px) {
    height: 6rem;
  }
}
.c-unit-tile__pic {
  padding-bottom: 40%;
  display: block;
  overflow: hidden;
  position: relative;

  @include bp(500px) {
    border-radius: 50%;
    padding-bottom: 330px;
    margin: 0 calc((100% - 330px) / 2)
  }
}
.c-unit-tile__img {
  @include bp-down(500px) {
    object-fit: cover;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    object-position: center;
    margin: 0!important;
  }

  @include bp(500px) {
    max-height: 100%;
  }
  position: absolute;
}
.c-unit-tile__text {
  margin-top: 0.75rem;
  text-align: center; 
}
.c-unit-tile__btn {
  background: $color-brand;
  color: $color-white;
  font-family: 'CircularPro', sans-serif;
  font-weight: 700;
  width: 100%;
  margin-top: 1.125rem;
  border: none;
  border-radius: 1.5625rem;
  padding: 1rem 0;

  @include bp(500px) {
    display: none;
  }
}
</style>