import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  userRole: null,
  noAccess: false,
  setLocale: 'nb',
  slug: 'aktor',
  langs: [
    {
      "code": "nb",
      "slug": "aktor",
      "label": "Bokmål"
    },
    {
      "code": "nn",
      "slug": "aktor-nn",
      "label": "Nynorsk"
    }
  ],
  currentLocale: null
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
