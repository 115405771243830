import { fetchSH } from '@/api'

const fetchCourseBySlug = async courseSlug => {
  const data = await fetchSH('courses/' + courseSlug)

  return data
}


const fetchCourseUnits = async courseSlug => fetchSH('courses/' + courseSlug + '/units')

const fetchCourseAllVideoType = courseSlug => fetchSH('courses/' + courseSlug + '/videotype')

const fetchCourseAllAudioType = courseSlug => fetchSH('courses/' + courseSlug + '/audiotype')

const fetchCourseAllArticleType = courseSlug => fetchSH('courses/' + courseSlug + '/articletype')

const fetchCourseAllComponent = (courseSlug, componentName) => fetchSH('courses/' + courseSlug + '/component/' + componentName)

const fetchLessonActivity = courseSlug => fetchSH('courses/' + courseSlug + '/articletype')

const fetchUnitBySlug = async unitSlug => {
  const data = await fetchSH('units/' + unitSlug)

  return data
}

const fetchUnitLessons = async unitSlug => fetchSH('units/' + unitSlug + '/lessons')

const fetchUnitLessonsStripped = async unitSlug => fetchSH('units/' + unitSlug + '/lessonsStripped')

const fetchLessonsField = async (lessonSlug, field) => fetchSH('lessons/' + lessonSlug + '/parts/'+ field)

const fetchLesson = async lessonSlug => {
  const data = await fetchSH('lessons/' + lessonSlug)

  return data
}

const fetchLessonUnit = unitId => fetchSH('units/' + unitId)

const fetchCourseTags = async courseSlug => {
  const course = await fetchCourseBySlug(courseSlug)

  return course.tags
}

export {
  fetchCourseBySlug,
  fetchCourseUnits,
  fetchUnitBySlug,
  fetchUnitLessons,
  fetchUnitLessonsStripped,
  fetchLessonsField,
  fetchLesson,
  fetchLessonUnit,
  fetchCourseAllVideoType,
  fetchCourseAllAudioType,
  fetchCourseAllArticleType,
  fetchCourseAllComponent,
  fetchLessonActivity,
  fetchCourseTags
}
