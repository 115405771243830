import actions from './actions'
import mutations from './mutations'

const state = {
  content: null,
  isOpen: false
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}
