const setModal = ({commit}, content) => {
  commit('SET_MODAL', content)
}

const clearModal = ({commit}) => {
  commit('CLEAR_MODAL')
}

export default {
  setModal,
  clearModal
}