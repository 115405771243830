<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1253.57 1710.42" xml:space="preserve">
    <g id="Layer_2">
      <g>
        <path class="st4" d="M1139.91,169.97c8.29,2.68,17.1,4.16,26.27,4.16c47.13,0,85.34-38.19,85.34-85.3
          c0-38.5-25.53-71.02-60.56-81.63c-8.29-2.67-17.1-4.16-26.27-4.16c-47.13,0-85.34,38.2-85.34,85.31
          C1079.34,126.84,1104.87,159.36,1139.91,169.97z" :fill="fill" />
        <path class="st4" d="M902.14,160.15h-0.46c-89.54,0-173.77,34.85-237.19,98.14c-63.52,63.42-98.56,147.79-98.68,237.57
          C565.67,602.04,615.04,696.88,692,758.62l-57.65,90.12c-3.81-0.53-7.69-0.89-11.64-0.89c-6.97,0-13.69,0.93-20.18,2.52
          c-39.47,7.45-69.34,42.1-69.34,83.76c0,17.18,5.12,33.14,13.85,46.52l-57.43,89.77c-45.09-22.66-95.91-35.51-149.71-35.58h-0.45
          c-89.54,0-173.76,34.84-237.19,98.14c-63.52,63.42-98.57,147.79-98.68,237.57c-0.25,185.36,150.24,336.37,335.44,336.6h0.46
          c89.54,0,173.77-34.85,237.18-98.15c63.52-63.41,98.57-147.78,98.69-237.57c0.14-106.17-49.22-201.01-126.2-262.76l57.65-90.11
          c3.82,0.53,7.69,0.88,11.64,0.88c6.97,0,13.7-0.92,20.18-2.5c39.47-7.47,69.34-42.11,69.34-83.78c0-17.17-5.12-33.12-13.84-46.5
          l57.43-89.77c45.09,22.65,95.91,35.51,149.7,35.58h0.46c89.54,0,173.76-34.85,237.19-98.15c63.52-63.41,98.56-147.78,98.68-237.57
          C1237.82,311.39,1087.34,160.39,902.14,160.15z M481.71,1513.74c-38.04,37.96-88.53,58.85-142.25,58.85h-0.27
          c-111.08-0.14-201.33-90.69-201.19-201.86c0.07-53.85,21.1-104.46,59.2-142.48c38.03-37.96,88.53-58.87,142.25-58.87h0.27
          c27.17,0.04,53.06,5.55,76.73,15.39l-102.52,160.24c-10.54,16.48-5.75,38.38,10.71,48.92c16.46,10.54,38.35,5.73,48.87-10.73
          l102.42-160.08c39.91,36.91,65.05,89.59,64.97,148.13C540.84,1425.11,519.81,1475.7,481.71,1513.74z M1043.95,639.06
          c-38.03,37.96-88.53,58.85-142.25,58.85h-0.27c-27.17-0.03-53.06-5.54-76.73-15.38l102.52-160.25
          c10.54-16.48,5.75-38.37-10.72-48.92c-16.46-10.55-38.34-5.75-48.87,10.72L765.23,644.17c-39.91-36.92-65.05-89.58-64.97-148.12
          c0.06-53.85,21.08-104.46,59.19-142.48c38.03-37.97,88.53-58.87,142.25-58.87h0.26c111.09,0.15,201.34,90.71,201.19,201.88
          C1103.07,550.43,1082.05,601.02,1043.95,639.06z" :fill="fill" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#FFF'
    }
  }
}
</script>