const getTag = state => (slug, tag) => {
  const unit = state.units.filter(unit => unit.slug === slug)[0]

  return unit.tags.filter(t => t.id === tag)[0]
}

const getCourse = state => {
  const content = state.course

  return content
}

const getUnits = state => {
  const content = state.units

  return content
}

const getUnit = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0]

  return content
}

const getUnitParsedContent = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0].parsedContent

  return content
}

const getLessons = state => slug => {
  const content = state.units.filter(unit => unit.slug === slug)[0].lessons

  return content
}

const getLessonsField = state => (slug, field) => {
  const content = state.lessons[slug][field]

  return content
}

const getLessonActivities = state => (unitSlug, lessonSlug) => {
  const unit = state.units.filter(u => u.slug === unitSlug)[0]
  const lesson = unit.lessons.filter(l => l.slug === lessonSlug)[0]

  return lesson.activities
}

export default {
  getTag,
  getCourse,
  getUnits,
  getUnit,
  getUnitParsedContent,
  getLessons,
  getLessonsField,
  getLessonActivities
}
