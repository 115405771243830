const addBannerHeight = ({commit}, height) => {
  commit('SET_BANNER_HEIGHT', height)
}

const clearBanner = ({commit}) => {
  commit('CLEAR_BANNER')
}

export default {
  addBannerHeight,
  clearBanner
}